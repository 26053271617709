@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Mattone-Regular';
  src: url('/fonts/Mattone-Regular.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Mena-Grotesk';
  src: url('/fonts/Mena\ Grotesk\ Book.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Mena-Grotesk';
  src: url('/fonts/Mena\ Grotesk\ Medium.otf');
  font-weight: bold;
}

body {
  background-color: black;
  // background: url('~assets/images/background.jpg') no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  color: white;

  * {
    box-sizing: border-box;
  }
}



body::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
  background-color: #8D2AD3;
	border: 0;
}
